import React from 'react';
import { Card, Col, Row, Tabs, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

type Product = {
    frame_sides_areo: number;
    opening_areo: string;
    min_passage_width?: number;
    max_passage_width?: number;
    min_passage_height?: number;
    max_passage_height?: number;
    min_overall_width?: number;
    max_overall_width?: number;
    min_overall_height?: number;
    max_overall_height?: number;
}

type ProductUserChoice = {
    width: number;
    height: number;
    measureType: string;
}

type ProductCardProps = {
    product: Product;
    actions?: React.ReactNode[];
    userChoice?: ProductUserChoice;
}

const getProductVersionImage = (productVersion: Product) => {

    let openingAreo = productVersion.opening_areo;

    if (productVersion.opening_areo.indexOf('modular') !== -1) {
        openingAreo = 'modular';
    }

    return `/build/images/configurator/areo-tech/${openingAreo}_frames_${productVersion.frame_sides_areo}.svg`;
}

const extractProductVersionText = (productVersion: Product, t: TFunction) => {
    return t(`products.version.${productVersion.opening_areo}_frames_${productVersion.frame_sides_areo}`);
}

const ProductCard = ({ product, userChoice, actions }: ProductCardProps) => {

    const { t } = useTranslation();

    const productVersionText = extractProductVersionText(product, t);    

    return (
        <Card
            cover={
                <div>
                    <Typography.Title className='text-center' level={5}>{productVersionText}</Typography.Title>
                    <img src={getProductVersionImage(product)} alt={productVersionText} />
                </div>
            }
            actions={actions}>
            <Row gutter={[16, 16]}>
                
                {userChoice && (
                    <Col xs={24} lg={24}>
                        <Typography.Text strong={true} className='text-uppercase'>
                            {t('configurator.measure_mode')}: {t(`configurator.${userChoice.measureType}`)}
                        </Typography.Text>
                        <br />
                        <span>{t('configurator.width')}: {userChoice.width} mm</span><br />
                        <span>{t('configurator.height')}: {userChoice.height} mm</span>
                    </Col>
                )}

                {!userChoice && (
                    <Col xs={24} lg={24}>
                        <Tabs size='small' tabPosition="top" defaultActiveKey='1'>                            
                            <Tabs.TabPane tab={t('configurator.overall')} key="1">
                                <span>{t('configurator.width')}: {product.min_overall_width} - {product.max_overall_width} mm</span><br />
                                <span>{t('configurator.height')}: {product.min_overall_height} - {product.max_overall_height} mm</span>
                            </Tabs.TabPane>
                        </Tabs>
                    </Col>
                )}
            </Row>
        </Card>
    );
}

export default ProductCard;